import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return(
      <footer className="footer" style={{ fontSize: `14px` }}> © 2023. Desenvolvido por Check Ok.</footer>
    )
  }
}

export default Footer
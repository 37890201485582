import React, { Component } from 'react'
import Helpers from '../../../helpers/index'

class Detail extends Component {
  constructor(props) {
    super()

    /* Initial values */
    const date = new Date()

    this.state = {
      longDateString: date.toLocaleString('pt-BR', { month: 'long' }) + ' de ' + date.getFullYear()
    }
  }

  handleShowList = (event) => {
    this.props.onShowList()
  }

  render() {    
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row gx-3 gy-2 align-items-right">
                <div className="col-sm-7">
                  <div className="card-title d-flex align-items-center">
                    <i class="mdi mdi-arrow-left ticket-title-icon" onClick={this.handleShowList}></i>
                    <h4 class="ticket-title-text">Boleto {this.props.data.id}</h4>
                  </div>
                  <h6 className="card-subtitle">{this.state.longDateString}</h6>
                </div>
              </div>

              <hr />

              <div className="table-responsive">
                <table className="table stylish-table">
                  <thead>
                    <tr>
                      <th>Código do Produto</th>
                      <th>Descrição</th>
                      <th className="text-center">Quantidade</th>
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.data.data.detail.map((item) => 
                      <tr>
                        <td>
                          <h5>
                            <span>{item.product_code}</span>
                          </h5>
                        </td>
                        <td>
                          <h5>
                            <span>{item.description ? item.description : '-'}</span>
                          </h5>
                        </td>
                        <td>
                          <h5 className="text-center">
                            <span>{item.qty}</span>
                          </h5>
                        </td>
                        <td>
                          <h5 className="text-center">
                            <span>{Helpers.currencyMask(item.total)}</span>
                          </h5>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <h6><span className="link" style={{ color: 'limegreen', fontWeight: 'bold' }}>TOTAL GERAL</span></h6>
                      </td>
                      <td>
                        <h5 className="text-center">
                          <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.props.data.data.qty}</span>
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-center">
                          <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{Helpers.currencyMask(this.props.data.data.amount)}</span>
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Detail
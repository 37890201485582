import React, { Component } from 'react'
import AxiosHttpClient from '../../../../infrastructure/http/axios-http-client'
import { LoadingAnimation, JsonVisualizer } from '../../../components'

class List extends Component {
  constructor(props) {
    super()

    /* Initial values */
    this.state = {
      data: [],
      isLoading: true
    }
  }

  handleShowRegistration = (event) => {
    this.props.onShowRegistration(event.target.value)
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({ isLoading: true })

    AxiosHttpClient.post("/login/load-accounts", undefined, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.data && response.data !== '') {
        this.setState({ data: response.data })
      }

      setTimeout(() => { this.setState({ isLoading: false })}, 100)
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  render() {
    return (
      <div className="row">
        { process.env.REACT_APP_ENVIRONMENT === `DEVELOPMENT` ? <JsonVisualizer json={{ data: this.state.data }} /> : ``}

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="row gx-3 gy-2 align-items-right">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <h4 className="card-title">Usuários</h4>
                  <h6 className="card-subtitle">Ordenados por ID</h6>
                </div>

                <div className="col-lg-8 col-md-8 col-sm-8 d-flex justify-content-end">
                  <button type="button" className="btn waves-effect waves-light btn-outline-primary" style={{ height: '40px' }} onClick={this.handleShowRegistration}>Adicionar usuário</button>
                </div>
              </div>

              <hr />

              <div className="table-responsive">
                {this.state.isLoading
                  ?
                  <LoadingAnimation />
                  :
                  <table className="table stylish-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>Tipo de Usuário</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((value, key) => 
                        <tr key={key}>
                          <td>
                            <h6>
                              <button className="btn btn-link">
                                {value.id}
                              </button>
                            </h6>
                          </td>
                          <td>
                            <h6>
                              {value.name}
                            </h6>
                          </td>
                          <td>
                            <h6>
                              {value.login}
                            </h6>
                          </td>
                          <td>
                            <h6>
                              {value.role !== undefined && value.role !== 'undefined' ? <span class="label label-primary">{value.role}</span> : <span class="label label-info">Usuário comum</span>}
                            </h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default List
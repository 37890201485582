export default function renderSidebarIcon(item) {
  switch(item) {
    case 'Boletos':
      return 'mdi mdi-cash'
    
    case 'Custo do Fornecedor':
      return 'mdi mdi-briefcase'

    case 'Clientes':
      return 'mdi mdi-wallet'  

    case 'Usuários':
      return 'mdi mdi-account'

    case 'Configurações':
      return 'mdi mdi-settings'
    
    default:
      return 'mdi mdi-gauge'
  }
}
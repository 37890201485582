import React, { Component } from 'react'
import AxiosHttpClient from '../../../infrastructure/http/axios-http-client'
import List from './components/list'
import Detail from './components/detail'

class Invoices extends Component {
  constructor(props) {
    super()
    
    /* Binding functions */
    this.handleShowList = this.handleShowList.bind(this)
    this.handleShowInvoiceDetail = this.handleShowInvoiceDetail.bind(this)

    /* Setting initial states */
    this.state = {
      isLoading: true,
      isShown: 'list',
      invoice: {
        id: null,
        data: null
      }
    }
  }

  handleShowList = () => {
    this.setState({
      invoice: {
        id: null,
        data: null
      },
      isShown: 'list'
    })
  }

  handleShowInvoiceDetail = (invoiceId) => {
    this.setState({ isLoading: true })

    AxiosHttpClient.post('/invoices/detail', `{ "number": "${invoiceId}" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.data && response.data !== '') {
        this.setState({
          invoice: {
            id: invoiceId,
            data: response.data
          },
          isShown: 'single'
        })
      }

      setTimeout(() => { this.setState({ isLoading: false }) }, 100)
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {this.state.isShown === 'list' ? (<List onShowInvoiceDetail={this.handleShowInvoiceDetail} />) : (<Detail data={this.state.invoice} onShowList={this.handleShowList} />)}
        </div>
      </div>
    )
  }
}

export default Invoices
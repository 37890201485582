import axios from 'axios'
import AuthTokenValidation from '../../presentation/validation/auth-token-validation'

const authTokenValidation = new AuthTokenValidation()

const AxiosHttpClient = axios.create({
  baseURL: process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? process.env.REACT_APP_BACKEND_API_ENDPOINT : process.env.REACT_APP_BACKEND_STAGING_API_ENDPOINT,
  timeout: 0
})

// Adding Axios interceptor to handle 401 error without join on catch
AxiosHttpClient.interceptors.response.use(
  (success) => {
    return success
  },
  (error) => {
    // authTokenValidation.unsetToken()

    return error
  }
)

export default AxiosHttpClient
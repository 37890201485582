import React, { Component } from 'react'
import logoImage from '../assets/images/white-logo.png'

class Header extends Component {
  constructor(props) {
    super(props)

    this.sidebarToggler = this.sidebarToggler.bind(this)
    this.dropdownMenuToggler = this.dropdownMenuToggler.bind(this)
  }

  dropdownMenuToggler = (event) => {
    let classToFind = 'show'
    let elementToChange = '.dropdown'

    if(document.querySelector(elementToChange).classList.contains(classToFind)) {
      document.querySelector(elementToChange).classList.remove(classToFind)
    }

    else {
      document.querySelector(elementToChange).classList.add(classToFind)
    }
  }

  sidebarToggler = (event) => {
    let classToFind = 'mini-sidebar'
    let elementToChange = 'body'

    if(document.querySelector(elementToChange).classList.contains(classToFind)) {
      document.querySelector(elementToChange).classList.remove(classToFind)
      document.querySelector('.user-profile').classList.remove('d-none')
    }

    else {
      document.querySelector(elementToChange).classList.add(classToFind)
      document.querySelector('.user-profile').classList.add('d-none')
    }
  }

  render() {
    return(
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          <div className="navbar-header">
            <a href="/#" className="navbar-brand">
              <img src={logoImage} alt="Home page" className="light-logo" width="55" />
            </a>
          </div>

          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto mt-md-0">
              <li className="nav-item"><a href="/#" className="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark" onClick={this.sidebarToggler}><i className="ti-menu"></i></a></li>
            </ul>

            <ul className="navbar-nav my-lg-0">
              <li className="nav-item dropdown" onClick={this.dropdownMenuToggler}>
                <a href="/#" className="nav-link dropdown-toggle text-muted waves-effect waves-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="round round-warning"><i className="mdi mdi-account"></i></span></a>
                <div className="dropdown-menu dropdown-menu-right scale-up">
                  <ul className="dropdown-user">
                    <li><a href="/#"><i className="ti-user" style={{ marginRight: '15px' }}></i>{localStorage.getItem('nickname')}</a></li>
                    <li role="separator" className="divider"></li>
                    <li onClick={this.props.onConfigurationButtonClick}><a href="/#"><i className="ti-settings" style={{ marginRight: '15px' }}></i>Configurações</a></li>
                    <li role="separator" className="divider"></li>
                    <li onClick={this.props.onLogoutButtonClick}><a href="/#"><i className="fa fa-power-off" style={{ marginRight: '15px' }}></i>Sair</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
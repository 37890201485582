export default function getComponentToRender(index) {
  switch(index) {
    case 1:
      return 'Invoices'
    
    case 2:
      return 'SupplierCost'

    case 3:
      return 'Clients'
    
    case 4:
      return 'Users'  

    case 5:
      return 'Configuration'

    default:
      return 'Dashboard'
  }
}
class AuthTokenHelper {
  constructor() {
    this.signature = '#843jkds9432-3'
    this.jwt = require('jsonwebtoken')
  }

  getPayload = (token) => {
    return this.jwt.verify(token, this.signature)
  }

  getCostCenter = (token) => {
    let payload = this.jwt.verify(token, this.signature)
    
    return payload.id
  }
}

export default AuthTokenHelper
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function showAlert(data) {
  const swal = withReactContent(Swal)

  swal.fire({
    title: data.title,
    text: data.text,
    icon: data.icon
  })
}
import React, { Component } from 'react'

class Configuration extends Component {
  constructor(props) {
    super()

    this.state = {
      darkTheme: 0,
      showEmailOnLogin: 0
    }
  }

  componentDidMount() {
    this.enableDarkThemeVerification()
    this.enableShowEmailOnLogin()
  }

  enableDarkThemeVerification = () => {
    if(localStorage.getItem(`dark-theme`) == 1) {
      this.setState({ darkTheme: 1 })
    }
  }

  handleEnableDarkTheme = (event) => {
    if(this.state.darkTheme == 0) {
      this.setState({ darkTheme: 1 })
      localStorage.setItem(`dark-theme`, 1)
    }

    else {
      this.setState({ darkTheme: 0 })
      localStorage.setItem(`dark-theme`, 0)
    }

    window.location.reload()
  }

  enableShowEmailOnLogin = () => {
    if(localStorage.getItem(`show-email-on-login`)) {
      this.setState({ showEmailOnLogin: 1 })
    }
  }

  handleShowEmailOnLogin = () => {
    if(this.state.showEmailOnLogin === 1) {
      this.setState({ showEmailOnLogin: 0 })
      localStorage.setItem(`show-email-on-login`, 0)
    }

    else {
      this.setState({ showEmailOnLogin: 1 })
      localStorage.setItem(`show-email-on-login`, 1)
    }
  }

  render() {
    return(
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="row gx-3 gy-2 align-items-right">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h4 className="card-title">Configurações</h4>
                  <h6 className="card-subtitle">Altere aqui suas opções de usuário.</h6>

                  <hr />

                  <div className="layout-options">
                    <h4 className="card-title"><small>Opções de Layout</small></h4>

                    <div className="basic-checkbox">
                      <input type="checkbox" id="enable-dark-theme" className="filled-in" onChange={this.handleEnableDarkTheme} checked={this.state.darkTheme}></input>
                      <label htmlFor="enable-dark-theme">Habilitar tema escuro</label>
                    </div>
                  </div>

                  <hr />

                  <div className="user-options" style={{ display: 'none' }}>
                    <h4 className="card-title"><small>Opções de Usuário</small></h4>

                    <div className="basic-checkbox">
                      <input type="checkbox" id="show-email-on-login" className="filled-in" onChange={this.handleShowEmailOnLogin} checked={this.state.showEmailOnLogin}></input>
                      <label htmlFor="show-email-on-login">Habilitar função de salvar credenciais ao logar</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Configuration
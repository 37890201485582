import React, { useState, useEffect } from 'react'
import { Login, Main, PasswordRecovery } from './presentation/pages/index'
import Helpers from './presentation/helpers/index'
import AuthTokenValidation from './presentation/validation/auth-token-validation'

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(0)
  const [isPasswordRecoveryShown, setIsPasswordRecoveryShown] = useState(0)
  const authTokenValidation = new AuthTokenValidation()

  useEffect(() => {
    chooseLayoutTheme()

    if(authTokenValidation.verifyToken() === true) {
      setIsLoggedIn(true)
    }
    
    handleRecoveryPasswordParam()
  })
  
  const handleLogin = (apiResponse) => {
    if(apiResponse.code === 200) {
      setIsLoggedIn(true)
      authTokenValidation.setToken()
    }

    else {
      Helpers.showAlert({
        title: apiResponse.title,
        text: apiResponse.text,
        icon: apiResponse.icon
      })
    }
  }

  const handleRecoveryPasswordParam = () => {
    let url = new URL(window.location.href).pathname.split('/')

    if(url[1] === 'reset-password' && url[2] /* token */ !== '') {
      setIsPasswordRecoveryShown(true)
    }
  }

  const chooseLayoutTheme = () => {
    if(localStorage.getItem('dark-theme') == 1) {
      require('./presentation/assets/css/app-dark.min.css')
      require('./presentation/assets/css/green-dark-app.min.css')
    }
  }

  return(
    <div className="app">
      {console.log(`Rendering app component`)}

      {isLoggedIn === true ? <Main isLoggedIn={{ status: isLoggedIn }} /> : (isPasswordRecoveryShown ? <PasswordRecovery /> : <Login handleLogin={handleLogin} />)}
    </div>
  )
}
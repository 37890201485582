import React, { Component } from 'react'
import { Header, Breadcrumb, Content, Footer, Sidebar } from '../../components/index'
import AuthTokenValidation from '../../validation/auth-token-validation'
import '../../assets/index'

class Main extends Component {
  constructor(props) {
    super(props)

    this.sidebarReference = React.createRef()

    this.state = {
      loggedStatus: this.props.isLoggedIn,
      pageName: 'Encontro de Contas',
      breadcrumbItemName: 'Encontro de Contas',
      componentToRender: 'Dashboard',
      renderDarkTheme: false
    }

    this.authTokenValidation = new AuthTokenValidation()
  }

  componentDidMount() {
    this.handleInvoiceFilters()
    this.chooseLayoutTheme()
  }

  chooseLayoutTheme = () => {
    if(localStorage.getItem('dark-theme') == 1) {
      require('../../assets/css/app-dark.min.css')
      require('../../assets/css/green-dark-app.min.css')
    }
  }

  componentDidUpdate() {
    this.authTokenValidation.verifyToken()
  }

  handleInvoiceFilters = () => {
    let url = new URL(window.location.href)

    if(url.searchParams.has('filter')) {
      this.setState({
        pageName: 'Boletos',
        breadcrumbItemName: 'Boletos',
        componentToRender: 'Invoices'
      })

      this.sidebarReference.current.setActiveItem(1)
    }
  }

  handleBreadcrumb = (breadcrumbData) => {
    this.setState({
      pageName: breadcrumbData.pageName,
      breadcrumbItemName: breadcrumbData.breadcrumbItemName,
      componentToRender: breadcrumbData.componentToRender
    })
  }

  handleConfigurationButtonClick = () => {
    this.handleBreadcrumb({pageName: 'Configurações', breadcrumbItemName: 'Configurações', componentToRender: 'Configuration'})

    this.sidebarReference.current.setActiveItem(5)
  }

  handleLogoutButtonClick = () => {
    this.setState({
      loggedStatus: {
        status: false
      }
    })

    this.authTokenValidation.unsetToken()
  }
  
  render() {
    return(
      <div id="main-wrapper">
        <Header onSidebarTogglerButtonClick={this.sidebarToggler} onConfigurationButtonClick={this.handleConfigurationButtonClick} onLogoutButtonClick={this.handleLogoutButtonClick} />

        <Sidebar ref={this.sidebarReference} onSidebarButtonClick={this.handleBreadcrumb} onLogoutButtonClick={this.logoutButton} />

        <div className="page-wrapper">
          <div className="container-fluid">
            <Breadcrumb breadcrumbData={{ pageName: this.state.pageName, breadcrumbItemName: this.state.breadcrumbItemName }} />

            <Content content={{ title: this.state.pageName, componentToRender: this.state.componentToRender }} />
          </div>

          <Footer />
        </div>
      </div>
    )
  }
}

export default Main
import React, { Component } from 'react'

class Error extends Component {
  render() {
    return(
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="error-box">
            <div className="error-body text-center">
              <h1>{this.props.data.code}</h1>
              <h3 className="text-uppercase">{this.props.data.message}</h3>
              <p className="text-muted m-t-30 m-b-30">{this.props.data.subMessage}</p>
              <a href="/#" className="btn btn-primary btn-rounded waves-effect waves-light m-b-40">Voltar</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Error
import Helpers from '../../presentation/helpers/index'

class DashboardUsecase {
  cards = {
    firstCard: {
      details: {
        color: '#7d03bf',
        title: 'Resumo',
        explanation: null,
      },
      data: {
        billing: {
          id: '',
          clickable: false,
          label: '(+) Faturamento',
          value: Helpers.currencyMask(0)
        },
        increases: {
          id: '',
          clickable: false,
          label: '(*) Acréscimo',
          value: Helpers.currencyMask(0)
        },
        taxes: {
          id: '',
          clickable: false,
          label: '(-) Impostos',
          value: Helpers.currencyMask(0)
        },
        supplierCost: {
          id: '',
          clickable: false,
          label: '(-) Custo do Fornecedor',
          value: Helpers.currencyMask(0)
        },
        total: {
          id: '',
          clickable: false,
          label: '(=) R$',
          value: Helpers.currencyMask(0)
        },
        potentialTransfer: {
          id: '',
          clickable: false,
          label: 'Potencial de Repasse',
          value: Helpers.currencyMask(0)
        }
      }
    },
    secondCard: {
      details: {
          color: '#fbb03b',
          title: 'Boletos em Aberto',
          explanation: null
      },
      data: {
        duePayment: {
          id: '',
          clickable: false,
          label: '(*) À Vencer',
          value: Helpers.currencyMask(0)
        },
        nonPayment: {
          id: '',
          clickable: false,
          label: '(*) Inadimplência',
          value: Helpers.currencyMask(0)
        }
      }
    },
    thirdCard: {
      details: {
          color: '#ce1d8f',
          title: 'Boletos Recebidos',
          explanation: null
      },
      data: {
        amountReceived: {
          id: '',
          clickable: false,
          label: '(+) Valor Recebido',
          value: Helpers.currencyMask(0)
        },
        fees: {
          id: '',
          clickable: false,
          label: '(*) Juros/Multa',
          value: Helpers.currencyMask(0)
        },
        recoveredValue: {
          id: '',
          clickable: false,
          label: '(*) Valor Recuperado',
          value: Helpers.currencyMask(0)
        }
      }
    },
    fourthCard: {
      details: {
          color: '#1e88e5',
          title: 'Repasse',
          explanation: '* Repasse calculado sobre o valor recebido.'
      },
      data: {
        transfer: {
          id: '',
          clickable: false,
          label: '(=) Total Recebido',
          value: Helpers.currencyMask(0)
        },
        demo: {
          id: '',
          clickable: false,
          label: '(-) DEMO',
          value: Helpers.currencyMask(0)
        },
        discounts: {
          id: '',
          clickable: false,
          label: '(*) Descontos Pré-Faturamento',
          value: Helpers.currencyMask(0)
        },
        taxes: {
          id: '',
          clickable: false,
          label: '(-) Impostos',
          value: Helpers.currencyMask(0)
        },
        supplierCost: {
          id: '',
          clickable: false,
          label: '(-) Custo do Fornecedor',
          value: Helpers.currencyMask(0)
        },
        okValue: {
          id: '',
          clickable: false,
          label: '(-) Valor Rede OK',
          value: Helpers.currencyMask(0)
        },
        receivableAmount: {
          id: '',
          clickable: false,
          label: '(=) Valor à Receber',
          value: Helpers.currencyMask(0)
        }
      }
    }
  }

  tableFields = {
    detail: [],
    total: Helpers.currencyMask(0),
    totalAmount: Helpers.currencyMask(0),
    totalQuantity: 0
  }

  setResponseToCards = (response) => {
    return {
      firstCard: {
        details: {
          color: '#7d03bf',
          title: 'Resumo',
          explanation: null,
        },
        data: {
          billing: {
            id: '',
            clickable: false,
            label: '(+) Faturamento',
            value: Helpers.currencyMask(response.data.resume.billing)
          },
          increases: {
            id: 'increases',
            clickable: false,
            label: '(*) Acréscimo',
            value: Helpers.currencyMask(response.data.resume.increase)
          },
          taxes: {
            id: '',
            clickable: false,
            label: '(-) Impostos',
            value: Helpers.currencyMask(response.data.resume.deductions.tax)
          },
          supplierCost: {
            id: 'costCenter',
            clickable: false,
            label: '(-) Custo do Fornecedor',
            value: Helpers.currencyMask(response.data.resume.deductions.cost_provider)
          },
          total: {
            id: '',
            clickable: false,
            label: '(=) R$',
            value: Helpers.currencyMask(response.data.resume.initial_results),
          },
          potentialTransfer: {
            id: '',
            clickable: false,
            label: 'Potencial de Repasse',
            value: Helpers.currencyMask(response.data.resume.potential_results)
          }
        }
      },
      secondCard: {
        details: {
          color: '#fbb03b',
          title: 'Boletos em Aberto',
          explanation: null
        },
        data: {
          duePayment: {
            id: '',
            clickable: false,
            label: '(*) À Vencer',
            value: Helpers.currencyMask(response.data.resume.invoices_due)
          },
          nonPayment: {
            id: '',
            clickable: false,
            label: '(*) Inadimplência',
            value: Helpers.currencyMask(response.data.resume.non_payments)
          }
        }
      },
      thirdCard: {
        details: {
          color: '#ce1d8f',
          title: 'Boletos Recebidos',
          explanation: null
        },
        data: {
          amountReceived: {
            id: '',
            clickable: false,
            label: '(+) Valor Recebido',
            value: Helpers.currencyMask(response.data.resume.inbound)
          },
          fees: {
            id: 'late_payments',
            clickable: true,
            label: '(*) Juros/Multa',
            value: Helpers.currencyMask(response.data.resume.late_payments)
          },
          recoveredValue: {
            id: 'recoveries',
            clickable: true,
            label: '(*) Valor Recuperado',
            value: Helpers.currencyMask(response.data.resume.recovery.amount)
          }
        }
      },
      fourthCard: {
        details: {
          color: '#1e88e5',
          title: 'Repasse',
          explanation: '* Repasse calculado sobre o valor recebido.'
        },
        data: {
          amountReceived: {
            id: '',
            clickable: false,
            label: '(=) Total Recebido',
            value: Helpers.currencyMask(response.data.resume.inbound)
          },
          invoiceDemo: {
            id: 'demo',
            clickable: true,
            label: '(-) DEMO',
            value: Helpers.currencyMask(response.data.resume.deductions.invoice_demo)
          },
          discounts: {
            id: 'discounts',
            clickable: true,
            label: '(*) Descontos Pré-Faturamento',
            value: Helpers.currencyMask(response.data.resume.deductions.discounts)
          },
          taxes: {
            id: '',
            clickable: false,
            label: '(-) Impostos',
            value: Helpers.currencyMask(response.data.resume.deductions.tax)
          },
          supplierCost: {
            id: 'costCenter',
            clickable: false,
            label: '(-) Custo do Fornecedor',
            value: Helpers.currencyMask(response.data.resume.deductions.cost_provider)
          },
          okValue: {
            id: '',
            clickable: false,
            label: '(-) Valor Rede OK',
            value: Helpers.currencyMask(response.data.resume.division_results.amount.redeok)
          },
          receivableAmount: {
            id: 'partner',
            clickable: true,
            label: '(=) Valor à Receber',
            value: Helpers.currencyMask(response.data.resume.division_results.amount.partner)
          }
        }
      }
    }
  }

  setResponseToTableFields = (response) => {
    return {
      detail: response.data.resume.analytical.detail,
      total: Helpers.currencyMask(response.data.resume.analytical.total),
      totalAmount: Helpers.currencyMask(response.data.resume.analytical.total_amount),
      totalQuantity: response.data.resume.analytical.total_qty
    }
  }

  openFilteredInvoices = (filter, date, partner, costCenter) => {
    window.open(`?filter=${filter}&date=${date.getMonth() + 1}/${date.getFullYear()}&costCenter=${costCenter === ' ' ? '' : costCenter}&partner=${partner === ' ' ? '' : partner}`, '_blank')
  }
}

export default DashboardUsecase
import React, { Component } from 'react'
import loadingGif from '../assets/images/loading-gif.gif'

class LoadingAnimation extends Component {
  render() {
    return(
      <div className="col-lg-12 col-md-12" style={this.props.style}>
        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <img style={{ width: '40px', margin: '15px 0' }} src={ loadingGif } alt="Loading gif" />
        </div>
      </div>
    )
  }
}

export default LoadingAnimation
import React, { Component } from 'react'
import List from './components/list'
import Registration from './components/registration'

class Users extends Component {
  constructor(props) {
    super()
    
    /* Binding functions */
    this.handleShowList = this.handleShowList.bind(this)
    this.handleShowRegistration = this.handleShowRegistration.bind(this)

    /* Initial values */
    this.state = {
      isLoading: false,
      isShown: 'list'
    }
  }

  handleShowList = () => {
    this.setState({ isShown: 'list' })
  }

  handleShowRegistration = () => {
    this.setState({ isShown: 'registration' })
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {this.state.isShown === 'list' ? (<List onShowRegistration={this.handleShowRegistration} />) : (<Registration onShowList={this.handleShowList} />)}
        </div>
      </div>
    )
  }
}

export default Users
import React, { Component } from 'react'
import AxiosHttpClient from '../../../../infrastructure/http/axios-http-client'

class Registration extends Component {
  constructor(props) {
    super()

    /* Initial values */
    const wallets = localStorage.wallets.split(',')

    this.state = {
      costCenterList: [],
      wallers: wallets,
      isErrorCardShown: false
    }
  }

  componentDidMount() {
    this.getCostCenterList(localStorage.getItem('cost-center'))
  }
  
  getCostCenterList = (seller) => {
    this.setState({ isLoading: true })

    AxiosHttpClient.post('/login/load-cost-center', `{ "id": "${seller}" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.data && response.data !== '') {       
        this.setState({ costCenterList: response.data.costCenter })
      }
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })

    setTimeout(() => { this.setState({ isLoading: false }) }, 100)
  }

  performRegistration = (event) => {
    event.preventDefault()

    let data = {
      username: event.target.username.value,
      email: event.target.email.value,
      password: event.target.password.value,
      passwordConfirmation: event.target.passwordConfirmation.value,
      costCenter: event.target.costCenter.value,
      role: 'admin',
      wallets: Array.from(document.querySelectorAll('input[type="checkbox"]:checked')).map(v => v.value).join(', ')
    }

    let json = `
      {
        "name": "${data.username}",
        "email": "${data.email}",
        "password": "${data.password}",
        "passwordConfirmation": "${data.passwordConfirmation}",
        "role": "${data.role}",
        "costCenter": "${data.costCenter}",
        "wallets": "${data.wallets}"
      }
    `
    
    const callingRegisterEndpoint = async (json) => {
      await AxiosHttpClient.post('/signup', json, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})
  
      .then((response) => {
        if(response.status === 200) {
          this.setState({ isErrorCardShown: false })

          this.props.onShowList()
        }

        else {
          this.setState({ isErrorCardShown: true })
        }
      })
      
      .catch((error) => {
        this.setState({ isErrorCardShown: true })
      })
    }

    callingRegisterEndpoint(json)
  }

  closeErrorCard = () => {
    this.setState({ isErrorCardShown: false })
  }

  handleShowList = (event) => {
    this.props.onShowList()
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          { this.state.isErrorCardShown ?
            <div className="alert alert-danger alert-rounded">
              <i className="ti-user"></i> Erro na criação do usuário. Tente novamente.
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style={{ padding: '0 10px' }} onClick={this.closeErrorCard}>×</span>
              </button>
            </div>
          :
            ''
          }

          <div className="card">
            <div className="card-body">
              <div className="row gx-3 gy-2 align-items-right">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card-title d-flex align-items-center">
                    <i className="mdi mdi-arrow-left user-title-icon" onClick={this.handleShowList}></i>
                    <h4 className="user-title-text">Formulário de Cadastro de Usuários</h4>
                  </div>
                </div>
              </div>

              <div>
                <form className="form p-t-20" onSubmit={this.performRegistration}>
                  <div className="form-group">
                    <label for="username">Nome de Usuário</label>
                    <div className="input-group">
                      <div className="input-group-addon"><i className="ti-user"></i></div>
                      <input type="text" className="form-control" id="username" placeholder="Digite o nome de usuário" required={true}></input>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="email">Endereço de E-mail</label>
                    <div className="input-group">
                      <div className="input-group-addon"><i className="ti-email"></i></div>
                      <input type="email" className="form-control" id="email" placeholder="Digite o e-mail" required={true}></input>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="password">Senha</label>
                    <div className="input-group">
                      <div className="input-group-addon"><i className="ti-lock"></i></div>
                      <input type="password" className="form-control" id="password" placeholder="Digite a senha" required={true}></input>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="passwordConfirmation">Confirmação de Senha</label>
                    <div className="input-group">
                      <div className="input-group-addon"><i className="ti-lock"></i></div>
                      <input type="password" className="form-control" id="passwordConfirmation" placeholder="Digite a confirmação de senha" required={true}></input>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Centro de Custo</label>

                    <div className="input-group">
                      <select className="custom-select" id="costCenter" style={{ marginLeft: '0' }} onChange={this.costCenterSelector} required={true}>
                        <option value="">Selecione o centro de custo</option>
                        {this.state.costCenterList.map((cost) => <option value={cost.value}>{cost.description}</option>)}
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Carteiras</label>

                    {localStorage.wallets.split(',').map((wallet, index) =>
                      <div className="checkbox checkbox-success">
                        <input id={"ckb-" + index} type="checkbox" value={wallet} name="walletsList[]"></input>
                        <label for={"ckb-" + index}>{wallet}</label>
                      </div>
                    )}
                  </div>

                  <button type="submit" className="btn btn-success waves-effect waves-light m-r-10">Salvar</button>
                  <button type="submit" className="btn btn-inverse waves-effect waves-light" onClick={this.handleShowList}>Cancelar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Registration
import React, { Component } from 'react'
import { Dashboard, Invoices, SupplierCost, Clients, Users, Configuration, Error } from '../pages/index'

class Content extends Component {
  render() {
    document.title = `Portal do Parceiro - ${this.props.content.title}`

    switch(this.props.content.componentToRender) {
      case 'Dashboard':
        return <Dashboard />

      case 'Invoices':
        return <Invoices />

      case 'Clients':
        return <Clients />

      case 'SupplierCost':
        return <SupplierCost />

      case 'Configuration':
        return <Configuration />

      case 'Users':
        return <Users />
      
      default:
        return (
          <Error data={{ code: 404, message: "Página não encontrada", subMessage: "Parece que esta página não existe. Tem certeza de que está no lugar certo?" }} />
        )
    }
  }
}

export default Content
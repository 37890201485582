import React, { Component } from 'react'

class Breadcrumb extends Component {
  render() {
    return(
      <div className="row page-titles">
        <div className="col-md-12 col-12 align-self-center">
          <h3 className="text-themecolor">{this.props.breadcrumbData.pageName}</h3>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/#">Página Inicial</a></li>
            <li className="breadcrumb-item active">{this.props.breadcrumbData.breadcrumbItemName}</li>
          </ol>
        </div>
      </div>
    )
  }
}

export default Breadcrumb
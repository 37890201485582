import React, { Component } from 'react'
import AxiosHttpClient from '../../../infrastructure/http/axios-http-client'
import backgroundImage from '../../assets/images/background.jpg'
import Helpers from '../../helpers/index'

class PasswordRecovery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: '',
      alert: {
        display: 'none',
        color: localStorage.getItem('dark-theme') ? '#FFFFFF' : '#67757C',
        message: ''
      }
    }
  }

  componentDidMount() {
    this.getToken()
  }

  getToken = () => {
    let url = new URL(window.location.href).pathname.split('/')
    let token = url[2]

    token && token !== '' ? this.setState({ token: token }) : window.location.reload()
  }

  setNewPassword = (event) => {
    event.preventDefault()

    let token = event.target.token.value
    let password = event.target.password.value
    let passwordConfirmation = event.target.passwordConfirmation.value
    
    if(password !== passwordConfirmation) {
      Helpers.showAlert({
        title: 'Ooops...',
        text: 'Sua confirmação de senha deve ser igual à sua senha.',
        icon: 'error'
      })
    }

    else {
      AxiosHttpClient.post('/login/update-password', `{ "password": "${password}", "token": "${token}" }`, { headers: { 'Content-Type': 'application/json' }})

      .then((response) => {
        if(response.status === 200) {
          Helpers.showAlert({
            title: 'Tudo certo por aqui!',
            text: 'Sua senha foi alterada com sucesso.',
            icon: 'success'
          })


        }
      })
      
      .catch((error) => {
        Helpers.showAlert({
          title: 'Ooops...',
          text: 'Um erro ocorreu ao trocar sua senha. Tente novamente mais tarde ou entre em contato com a equipe de suporte.',
          icon: 'error'
        })
      })
    }
  }

  render() {
    document.title = `Portal do Parceiro - Troca de Senha`

    return(
        <section id="wrapper">
          <div className="login-register" style={{backgroundImage: `url(${backgroundImage})`, boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.45)'}}>        
            <div className="login-box card">
              <div className="card-body">
                <form className="form-horizontal form-material" action="#" onSubmit={this.setNewPassword}>
                    <h3 className="box-title m-b-20">Troca de Senha</h3>

                    <div className="form-group ">
                      <div className="col-xs-12">
                        <input className="form-control" type="text" required="required" placeholder="Código de recuperação" value={this.state.token} hidden disabled name="token" />
                      </div>
                    </div>

                    <div className="form-group ">
                      <div className="col-xs-12">
                        <input className="form-control" type="password" required="required" placeholder="Nova senha" name="password" />
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <div className="col-xs-12">
                        <input className="form-control" type="password" required="required" placeholder="Confirmação da nova senha" name="passwordConfirmation" />
                      </div>
                    </div>

                    <div className="form-group text-center m-t-20">
                      <div className="col-xs-12">
                        <button className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Resetar</button>
                      </div>
                    </div>

                    <div className="form-group text-center m-t-20 alert" style={{ display: this.state.alert.display, color: this.state.alert.color }}>
                      {this.state.alert.message}
                    </div>
                </form>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default PasswordRecovery
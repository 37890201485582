import React, { Component } from 'react'
import AxiosHttpClient from '../../../infrastructure/http/axios-http-client'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { LoadingAnimation, JsonVisualizer } from '../../components'
import Card from './components/card'
import Helpers from '../../helpers/index'
import DashboardUsecase from '../../../data/usecases/dashboard'

registerLocale('pt-BR', ptBR)

/* Usecases */
const dashboardUsecase = new DashboardUsecase()

class Dashboard extends Component {
  constructor(props) {
    super()
    const date = new Date()

    this.state = {
      /* Date fields */
      date: date,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      longDateString: new Date().toLocaleString('pt-BR', { month: 'long' }) + ' de ' + date.getFullYear(),

      /* Filter fields */
      costCenterList: [],
      costCenter: '',
      partner: '',

      /* Data fields */
      data: {
        cards: dashboardUsecase.cards,
        list: dashboardUsecase.tableFields
      },

      /* Layout fields */
      isLoading: false
    }
  }

  componentDidMount() {
    this.getCostCenterList(this.state.partner)
    this.getData(this.state.costCenter, this.state.partner, this.state.month, this.state.year)
  }

  getData = (costCenter, partner, month, year) => {
    this.setState({ isLoading: true })

    AxiosHttpClient.post('/account-rendering/resume', `{ "partner": "${partner}", "createdAt": "01/${month}/${year}", "costCenter": "${costCenter}" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.status === 200 && response.data && response.data !== '') {
        this.setState({
          data: {
            cards: dashboardUsecase.setResponseToCards(response),
            list: dashboardUsecase.setResponseToTableFields(response)
          }
        })

        setTimeout(() => { this.setState({ isLoading: false }) }, 100)
      }

      else {
        this.setState({
          data: {
            cards: dashboardUsecase.cards,
            list: dashboardUsecase.tableFields
          }
        })

        setTimeout(() => { this.setState({ isLoading: false }) }, 100)
      }
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  getCostCenterList = (partner) => {
    AxiosHttpClient.post('/login/load-cost-center', `{ "wallet": "${partner}" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.status === 200 && response.data && response.data !== '') {
        this.setState({ costCenterList: response.data.costCenter })
      }
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  costCenterSelector = (event) => {
    this.setState({
      costCenter: event.target.value
    })

    this.getData(event.target.value, this.state.partner, this.state.month, this.state.year)
  }

  dateSelector = (value) => {
    const month = value.toLocaleString('pt-BR', { month: 'long' })

    this.setState({
      date: value,
      month: value.getMonth() + 1,
      year: value.getFullYear(),
      longDateString: month + ' de ' + value.getFullYear()
    })

    this.getData(this.state.costCenter, this.state.partner, value.getMonth() + 1, value.getFullYear())
  }

  partnerSelector = (event) => {
    this.setState({
      partner: event.target.value
    })

    this.getCostCenterList(event.target.value)
    this.getData(this.state.costCenter, event.target.value, this.state.month, this.state.year)
  }

  invoicesByFilterSelector = (filter) => {
    dashboardUsecase.openFilteredInvoices(filter, this.state.date, this.state.partner, this.state.costCenter)
  }

  render() {
    return(
      <div class="row">
        { process.env.REACT_APP_ENVIRONMENT === `DEVELOPMENT` ? <JsonVisualizer json={{ data: this.state.data }} /> : `` }
        
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="row gx-3 gy-2 align-items-right">
                    <div className="ccol-lg-4 col-md-4 col-sm-4">
                      <h4 className="card-title">Relatório de Parceiros</h4>
                      <h6 className="card-subtitle">{this.state.longDateString}</h6>
                    </div>

                    <div className="col-lg-8 col-md-8 col-sm-8 d-flex justify-content-end">
                      <DatePicker 
                        dateFormat="MM/yyyy"
                        selected={this.state.date}
                        onChange={(date) => this.dateSelector(date)}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="Referência"
                        minDate={new Date(2022, 11 - 1, 1)}
                        maxDate={new Date()}
                      />

                      <select className="custom-select pull-right" onChange={this.partnerSelector}>
                        <option value="">Todas</option>
                        {localStorage.partners.split(',').map((partner, key) => <option key={key} value={partner}>{partner}</option>)}
                      </select>

                      <select className="custom-select pull-right" onChange={this.costCenterSelector}>
                        <option value="">Centro de Custo</option>
                        {this.state.costCenterList.map((costCenter, key) => <option key={key} value={costCenter.value}>{costCenter.description}</option>)}
                      </select>
                    </div>
                  </div>

                  <hr />

                  <div className="table-responsive">
                    {this.state.isLoading
                      ?
                      <LoadingAnimation />
                      :
                      <table className="table stylish-table">
                        <thead>
                          <tr>
                            <th>Código</th>
                            <th>Razão Social</th>
                            <th className="text-right">Quantidade</th>
                            <th className="text-right">Preço do Fornecedor</th>
                            <th className="text-right">Valor Faturado</th>
                          </tr>
                        </thead>
                        <tbody>
                          {typeof this.state.data.list.detail !== `undefined` && this.state.data.list.detail.length > 0 ?
                            <tr>
                              <td colSpan={2}>
                                <h6><span className="link" style={{ color: 'limegreen', fontWeight: 'bold' }}>TOTAL GERAL</span></h6>
                              </td>
                              <td>
                                <h5 className="text-right">
                                  <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.list.totalQuantity}</span>
                                </h5>
                              </td>
                              <td>
                                <h5 className="text-right">
                                  <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.list.totalAmount}</span>
                                </h5>
                              </td>
                              <td>
                                <h5 className="text-right">
                                  <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.list.total}</span>
                                </h5>
                              </td>
                            </tr>
                          :
                            ``
                          }

                          {typeof this.state.data.list.detail !== `undefined` && this.state.data.list.detail.length > 0 ?
                            this.state.data.list.detail.map((value, key) => 
                              <tr key={key} style={{ fontSize: '80%' }}>
                                <td style={{ width: '50px' }}>
                                  <h6>{value.customer_code}</h6>
                                </td>
                                <td>
                                  <h6>{value.company_name}</h6>
                                </td> 
                                <td>
                                  <h6 className="text-right">{value.qty}</h6>
                                </td>
                                <td>
                                  <h6 className="text-right">{Helpers.currencyMask(value.amount)}</h6>
                                </td>
                                <td>
                                  <h6 className="text-right">{Helpers.currencyMask(value.total)}</h6>
                                </td>
                              </tr>
                            )
                            :
                            <tr>
                              <td colSpan={10}>
                                Nenhum registro encontrado.
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              {this.state.data.cards && Object.keys(this.state.data.cards).map((card, index) =>
                <Card isLoading={this.state.isLoading}
                  invoicesByFilterSelector={this.invoicesByFilterSelector}
                  index={index} color={this.state.data.cards[card].details.color}
                  title={this.state.data.cards[card].details.title}
                  subtitle={this.state.longDateString}
                  data={this.state.data.cards[card].data}
                  explanation={this.state.data.cards[card].details.explanation
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard
class AuthTokenValidation {
  setToken = () => {
    const timeToExpire = new Date()
    timeToExpire.setHours(timeToExpire.getHours() + 1)
    localStorage.setItem('timeToExpire', timeToExpire)
  }
  
  verifyToken = () => {
    const now = new Date()
    const timeToExpire = localStorage.getItem('timeToExpire')

    if(timeToExpire == null) {
      // console.log('Login uninitialized.')

      localStorage.removeItem('timeToExpire')
    }
    
    else if(Date.parse(now) > Date.parse(timeToExpire)) {
      // console.log("Expired token found.")

      this.unsetToken()
      return false
    }
    
    else {
      // console.log("Valid token found.")

      return true
    }
  }

  unsetToken = () => {
    localStorage.removeItem('timeToExpire')
    window.location.reload()
  }
}
  
export default AuthTokenValidation
import React, { Component } from 'react'
import Helpers from '../helpers/index'
import userImage from '../assets/images/user-image.jpg'

class Sidebar extends Component {  
  constructor(props) {
    super(props)
    
    this.state = {
      activeItem: 0,
      items: ['Encontro de Contas', 'Boletos', 'Custo do Fornecedor', 'Clientes', 'Usuários', 'Configurações']
    }
  }

  setActiveItem = (index) => {
    this.setState({
      activeItem: index
    })
  }

  handleItemClick = (index, item) => {
    this.setActiveItem(index)

    this.props.onSidebarButtonClick({pageName: item, breadcrumbItemName: item, componentToRender: Helpers.getComponentToRender(index)})
  }

  render() {    
    return(
      <aside className="left-sidebar">
        <div className="scroll-sidebar">
          <div className="user-profile" style={{ background: `url(${userImage}) no-repeat` }}>
            <div style={{ padding: '50px 0' }}></div>

            <div className="profile-text">
              <a href="/#">{localStorage.getItem('nickname')}</a>
            </div>
          </div>

          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li className="nav-small-cap">Painel de Controle</li>

              {this.state.items.map((item, index) =>
                <li key={index} className={this.state.activeItem === index ? 'active' : ''} onClick={this.handleItemClick.bind(this, index, item)}>                  
                  <a className="waves-effect waves-dark" href="/#" aria-expanded="true">
                    <i className={Helpers.renderSidebarIcon(item)}></i>
                    <span className="hide-menu">{item}</span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
  
        <div className="sidebar-footer">
          <a href="/#" onClick={this.handleItemClick.bind(this, 5, 'Configurações')} className="link" data-toggle="tooltip" title="Configurações"><i className="ti-settings"></i></a>
          <a href="/#" className="link" data-toggle="tooltip" title="Clientes"><i className="mdi mdi-account"></i></a>
          <a href="/#" onClick={this.props.onLogoutButtonClick} className="link" data-toggle="tooltip" title="Logout"><i className="mdi mdi-power"></i></a>
        </div>
      </aside>
    )
  }
}

export default Sidebar
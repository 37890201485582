import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AxiosHttpClient from '../../../../infrastructure/http/axios-http-client'
import Helpers from '../../../helpers/index'
import { LoadingAnimation, JsonVisualizer } from '../../../components'
import InvoicesUsecase from '../../../../data/usecases/invoices'

/* Usecases */
const invoicesUsecase = new InvoicesUsecase()

class List extends Component {
  constructor(props) {
    super()

    /* Initial values */
    const date = new Date()

    this.state = {
      /* Date fields */
      date: date,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      longDateString: new Date().toLocaleString('pt-BR', { month: 'long' }) + ' de ' + date.getFullYear(),
      
      /* Filter fields */
      costCenterList: [],
      costCenter: '',
      partner: '',
      paymentStatus: '',
      filter : '',

      /* Data fields */
      data: invoicesUsecase.tableFields,

      /* Layout fields */
      checkedItems: new Map(),
      downloadLink: '',
      isLoading: true,
      isDownloadButtonShown: true,
      isDownloadLinkShown: false
    }
  }

  componentDidMount() {
    this.getData(this.state.costCenter, this.state.partner, this.state.month, this.state.year, this.state.paymentStatus)
    this.getCostCenterList(this.state.partner)
  }

  getData = (costCenter, partner, month, year, paymentStatus) => {
    this.setState({ isLoading: true })

    let url = new URL(window.location.href)
    let filter = ''

    if(url.searchParams.has('filter')) {
      costCenter = url.searchParams.get('costCenter')
      partner = url.searchParams.get('partner')
      month = url.searchParams.get('date').substring(0, url.searchParams.get('date').indexOf('/'))
      year = url.searchParams.get('date').substring(url.searchParams.get('date').indexOf('/') + 1)
      filter = `, "${url.searchParams.get('filter')}": "true"`

      this.setState({ costCenter: url.searchParams.get('costCenter') })
      this.setState({ partner: url.searchParams.get('partner') })
      this.setState({ month: month, year: year })
      this.setState({ filter: filter })
    }

    AxiosHttpClient.post('/invoices', `{ "partner": "${partner}", "createdAt": "01/${month}/${year}", "costCenter": "${costCenter}", "status": "${paymentStatus}" ${filter} }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.status === 200 && response.data && response.data !== '') {
        this.setState({
          data: invoicesUsecase.setResponseToTableFields(response)
        })

        setTimeout(() => { this.setState({ isLoading: false }) }, 100)
      }

      else {
        this.setState({
          data: invoicesUsecase.tableFields
        })

        setTimeout(() => { this.setState({ isLoading: false }) }, 100)
      }
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  getCostCenterList = (partner) => {
    AxiosHttpClient.post('/login/load-cost-center', `{ "wallet": "${partner}" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.status === 200 && response.data && response.data !== '') {
        this.setState({ costCenterList: response.data.costCenter })
      }
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  costCenterSelector = (event) => {
    this.setState({ costCenter: event.target.value })
 
    this.getData(event.target.value, this.state.partner, this.state.month, this.state.year, this.state.paymentStatus)
  }

  partnerSelector = (event) => {
    this.setState({ partner: event.target.value })

    this.getCostCenterList(event.target.value)
    this.getData(this.state.costCenter, event.target.value, this.state.month, this.state.year, this.state.paymentStatus)
  }

  statusSelector = (event) => {
    this.setState({ paymentStatus: event.target.value })

    this.getData(this.state.costCenter, this.state.partner, this.state.month, this.state.year, event.target.value)
  }

  setDate = (value) => {
    const month = value.toLocaleString('pt-BR', { month: 'long' })

    this.setState({
      date: value,
      month: value.getMonth() + 1,
      year: value.getFullYear(),
      longDateString: month + ' de ' + value.getFullYear()
    })

    this.getData(this.state.costCenter, this.state.partner, value.getMonth() + 1, value.getFullYear(), this.state.paymentStatus)
  }

  getDownloadFileLink = (event) => {
    event.preventDefault()

    AxiosHttpClient.post('/invoices', `{ "partner": "${this.state.partner}", "createdAt": "01/${this.state.month}/${this.state.year}", "costCenter": "${this.state.costCenter}", "status": "${this.state.paymentStatus}" ${this.state.filter}, "download": "true" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.status === 204) {
        this.setState({
          isDownloadLinkShown: false
        })
      }

      if(response.status === 200) {
        this.setState({
          isDownloadButtonShown: false,
          isDownloadLinkShown: true,
          downloadLink: response.data.path
        })
      }
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  closeDownloadFileLink = () => {
    this.setState({
      isDownloadButtonShown: true,
      isDownloadLinkShown: false
    })
  }

  handleShowInvoiceDetail = (event) => {
    this.props.onShowInvoiceDetail(event.target.value)
  }

  getCheckedInvoices = (event) => {
    let selectedData = []
    let checkedItems = document.querySelectorAll('input[type=checkbox]:checked')

    for(var i = 0; i < checkedItems.length; i++) {
      selectedData.push(checkedItems[i].id)
    }
  }

  render() {
    return (
      <div className="row">
        { process.env.REACT_APP_ENVIRONMENT === `DEVELOPMENT` ? <JsonVisualizer json={{ data: this.state.data }} /> : ``}

        { this.state.isDownloadLinkShown ?
          <div className="alert alert-success alert-rounded">
            <i className="ti-cloud-down"></i> <a target="blank" href={ this.state.downloadLink } style={{ color: '#155724' }}>O arquivo solicitado está pronto. Clique aqui para fazer o download!</a>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true" style={{ padding: '0 10px' }} onClick={this.closeDownloadFileLink}>×</span>
            </button>
          </div>
        :
          ''
        }

        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row gx-3 gy-2 align-items-right">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <h4 className="card-title">Boletos</h4>
                  <h6 className="card-subtitle">{this.state.longDateString}</h6>
                </div>

                <div className="col-lg-8 col-md-8 col-sm-8 d-flex justify-content-end">
                  <a href="/#" className="link" title="Get Checked Invoices" onClick={this.getCheckedInvoices} style={{ display: 'none' }}>
                    <i className="ti-share get-checked-invoices-icon"></i>
                  </a>

                  { this.state.isDownloadButtonShown ?
                    <a href="/#" className="link" title="Download Invoices in Excel File" onClick={this.getDownloadFileLink}>
                      <i className="ti-cloud-down invoices-download-icon"></i>
                    </a>
                  :
                    ''
                  }

                  <DatePicker 
                    dateFormat="MM/yyyy"
                    selected={this.state.date}
                    onChange={(date) => this.setDate(date)}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    locale="pt-BR"
                    className="form-control"
                    placeholderText='Referência'
                    minDate={new Date(2022, 11 - 1, 1)}
                    maxDate={new Date()}
                    value={this.state.month + "/" + this.state.year}
                  />

                  <select className="custom-select" onChange={this.statusSelector}>
                    <option value="">Status</option>
                    <option value="A">Em aberto</option>
                    <option value="B">Baixado</option>
                    <option value="C">Cancelado</option>
                  </select>

                  <select className="custom-select" onChange={this.partnerSelector}>
                    <option value="">Todas</option>
                    {localStorage.partners.split(',').map((partner) => <option selected={this.state.partner === partner ? "selected" : ""} value={partner}>{partner}</option>)}
                  </select>

                  <select className="custom-select" onChange={this.costCenterSelector}>
                    <option value="">Centro de Custo</option>
                    {this.state.costCenterList.map((cost) => <option selected={this.state.costCenter === cost.value ? "selected" : ""} value={cost.value}>{cost.description}</option>)}
                  </select>
                </div>
              </div>

              <hr />

              <div className="table-responsive">
                {this.state.isLoading
                  ?
                  <LoadingAnimation />
                  :
                  <table className="table stylish-table">
                    <thead>
                      <tr>
                        <th style={{ display: 'none' }}>Selecionar</th>
                        <th>Código</th>
                        <th>Razão Social</th>
                        <th>Boleto</th>
                        <th className="text-center">Emissão</th>
                        <th className="text-center">Centro de Custo</th>
                        <th className="text-center">Situação</th>
                        <th className="text-right">Quantidade</th>
                        <th className="text-right">Valor Consumido</th>
                        <th className="text-right">Valor Faturado</th>
                        <th className="text-right">Valor Pago</th>
                        <th className="text-right">Juros/Multa</th>
                        <th className="text-right">Data de Pagamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {typeof this.state.data.detail !== `undefined` && this.state.data.detail.length > 0 ?
                        <tr>
                          <td colSpan={6}>
                            <h6>
                              <span className="link" style={{ color: 'limegreen', fontWeight: 'bold' }}>TOTAL GERAL</span>
                            </h6>
                          </td>
                          <td>
                            <h5 className="text-right">
                              <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.totalQuantity}</span>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-right">
                              <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.totalAmount}</span>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-right">
                              <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.total}</span>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-right">
                              <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.paid}</span>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-right">
                              <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{Helpers.currencyMask(this.state.data.totalLatePayment)}</span>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-right">
                              <span style={{ color: 'limegreen', fontWeight: 'bold' }}>-</span>
                            </h5>
                          </td>
                        </tr>
                      :
                        ``
                      }

                      {typeof this.state.data.detail !== `undefined` && this.state.data.detail.length > 0 ?
                        this.state.data.detail.map((value, key) =>
                          <tr key={key}>
                            <td className="text-center" style={{ display: 'none' }}>
                              <div className="basic-checkbox">
                                <input type="checkbox" className="filled-in" id={value.invoice} onChange={null}></input>
                                <label htmlFor={value.invoice} style={{ height: '6px' }}></label>
                              </div>
                            </td>
                            <td style={{ width: '50px' }}>
                              <h6>{value.customer_code}</h6>
                            </td>
                            <td>
                              <h6><button className="btn btn-link" value={value.invoice} onClick={e => this.handleShowInvoiceDetail(e)}>{value.company_name}</button></h6>
                            </td>
                            <td>
                              <h6><button className="btn btn-link" value={value.invoice} onClick={e => this.handleShowInvoiceDetail(e)}>{value.invoice}</button></h6>
                            </td>
                            <td>
                              <h6 className="text-center">{value.invoice_date ? Helpers.dateHelper(value.invoice_date) : '-'}</h6>
                            </td>
                            <td>
                              <h6 className="text-center">{value.cost_center}</h6>  
                            </td>
                            <td>
                              <h6 className="text-center">{value.status}</h6>
                            </td>
                            <td>
                              <h6 className="text-right">{value.qty}</h6>
                            </td>
                            <td>
                              <h6 className="text-right">{Helpers.currencyMask(value.amount)}</h6>
                            </td>
                            <td>
                              <h6 className="text-right">{Helpers.currencyMask(value.total)}</h6>
                            </td>
                            <td>
                              <h6 className="text-right">{Helpers.currencyMask(value.paid)}</h6>
                            </td>
                            <td>
                              <h6 className="text-right">{value.late_payment !== null ? Helpers.currencyMask(value.late_payment) : "-"}</h6>
                            </td>
                            <td>
                              <h6 className="text-right">{value.status === 'B' && value.paid_date !== null ? value.paid_date : "-"}</h6>
                            </td>
                          </tr>
                        )
                      :
                        <tr>
                          <td colSpan={12}>
                            Nenhum registro encontrado.
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default List
import React, { Component } from 'react'
import { LoadingAnimation } from '../../../components'

class Card extends Component {
  setMargin = (label) => {
    let styles = {}
  
    // If label got a *, we need a tab
    if(label.includes('*')) {
      styles = {
        marginLeft: '30px'
      }
    }
  
    return styles
  }

  render() {
    return(
      <div className="card card-inverse card-primary" style={{ background: this.props.color, borderColor: this.props.color }}>
        <div className="card-body">
          <div className="d-flex">
            <div className="m-r-20 align-self-center">
              <h1 className="text-white"><i className="ti-money"></i></h1>
            </div>
            <div>
              <h3 className="card-title">{this.props.title}</h3>
              <h6 className="card-subtitle">{this.props.subtitle}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-12 align-self-center">
              {this.props.isLoading
                ?
                <LoadingAnimation style={{ color: '#FFF' }} />
                :
                <div>
                  <h2 className="font-light text-white" style={{ fontSize: '16px'}}>
                    {Object.keys(this.props.data).map((item, index) =>
                      <div className="d-flex justify-content-between" index={index} style={this.setMargin(this.props.data[item].label)}>
                        <span className={this.props.data[item].clickable === true ? 'card-item' : ''} onClick={this.props.data[item].clickable === true ? () => this.props.invoicesByFilterSelector(this.props.data[item].id) : undefined}>{this.props.data[item].label}</span>
                        <span style={{ fontSize: '1.2em' }}>{this.props.data[item].value}</span>
                      </div>
                    )}
                  </h2>

                  {this.props.explanation
                  ? 
                    <div className="font-light text-white">
                      <br />
                      <h6 className="card-subtitle">{this.props.explanation}</h6>
                    </div>
                  :
                    ''
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Card
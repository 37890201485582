import Dashboard from './dashboard/dashboard'
import Main from './main/main'
import Login from './login/login'
import PasswordRecovery from './login/password-recovery'
import Clients from './clients/clients'
import Invoices from './invoices/invoices'
import SupplierCost from './supplier-cost/supplier-cost'
import Users from './users/users'
import Configuration from './configuration/configuration'
import Error from './error/error'

export {
  Dashboard,
  Main,
  Login,
  PasswordRecovery,
  Clients,
  Invoices,
  SupplierCost,
  Users,
  Configuration,
  Error
}
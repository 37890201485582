import currencyMask from './currency-mask-helper'
import percentMask from './percent-mask-helper'
import dateHelper from './date-helper'
import renderSidebarIcon from './render-icon-helper'
import getComponentToRender from './get-component-to-render-helper'
import showAlert from './show-alert-helper'
import AuthTokenHelper from './auth-token-helper'

const Helpers = {
  currencyMask,
  renderSidebarIcon,
  getComponentToRender,
  showAlert,
  percentMask,
  dateHelper,
  AuthTokenHelper
}

export default Helpers
import React, { Component } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'
import AxiosHttpClient from '../../../infrastructure/http/axios-http-client'
import { LoadingAnimation, JsonVisualizer } from '../../components'
import Helpers from '../../helpers/index'

registerLocale('pt-BR', ptBR)

class SupplierCost extends Component {
  constructor(props) {
    super()

    /* Initial values */
    const date = new Date()
    const wallets = localStorage.wallets.split(',')

    this.state = {
      /* Date fields */
      date: date,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      longDateString: date.toLocaleString('pt-BR', { month: 'long' }) + ' de ' + date.getFullYear(),

      /* Filter fields */
      costCenter: '',
      costCenterList: [],
      wallet: wallets[0],

      /* Data fields */
      data: {
        detail: [], 
        total: 0, 
        totalAmount: 0, 
        totalQuantity: 0
      },      

      /* Layout fields */
      downloadLink: '',
      isLoading: true,
      isDownloadButtonShown: true,
      isDownloadLinkShown: false
    }
  }

  componentDidMount() {
    this.getData('', this.state.wallet, this.state.month, this.state.year)
    this.getCostCenterList(this.state.wallet)
  }
 
  getCostCenterList = (wallet) => {
    this.setState({ isLoading: true })
    
    AxiosHttpClient.post('/login/load-cost-center', `{ "wallet": "${wallet}" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.data && response.data !== '') {
        this.setState({ costCenterList: response.data.costCenter })
      }

      setTimeout(() => { this.setState({ isLoading: false }) }, 100)
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  getData = (costCenter, wallet, month, year) => {
    this.setState({ isLoading: true })

    AxiosHttpClient.post('/account-rendering', `{ "partner": "${wallet}", "costCenter": "${costCenter}", "createdAt": "01/${month}/${year}" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.data && response.data !== '') {
        this.setState({
          data: {
            detail: response.data.detail, 
            total: Helpers.currencyMask(response.data.total),
            totalAmount: Helpers.currencyMask(response.data.total_amount),
            totalQuantity: response.data.total_qty
          }
        })
      }

      setTimeout(() => { this.setState({ isLoading: false }) }, 100)
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  costCenterSelector = (event) => {
    this.setState({ costCenter: event.target.value })

    this.getData(event.target.value, this.state.wallet, this.state.month, this.state.year)
  }

  walletSelector = (event) => {
    this.setState({ wallet: event.target.value })

    this.getCostCenterList(event.target.value)
    this.getData(this.state.costCenter, event.target.value, this.state.month, this.state.year)
  }

  setDate = (value) => {
    const month = value.toLocaleString('pt-BR', { month: 'long' })

    this.setState({
      date: value,
      month: value.getMonth() + 1,
      year: value.getFullYear(),
      longDateString: month + ' de ' + value.getFullYear()
    })

    this.getData(this.state.costCenter, this.state.wallet, value.getMonth() + 1, value.getFullYear())
  }

  handleShowInvoiceDetail = (event) => {
    console.log(event.target.value)
  }

  getDownloadFileLink = () => {
    AxiosHttpClient.post('/account-rendering', `{ "partner": "${this.state.wallet}", "createdAt": "01/${this.state.month}/${this.state.year}", "costCenter": "${this.state.costCenter}", "status": "", "download": "true" }`, { headers: { 'Content-Type': 'application/json', 'x-access-token': `${localStorage.getItem(`x-access-token`)}` }})

    .then((response) => {
      if(response.data && response.data !== '') {
        this.setState({
          isDownloadButtonShown: false,
          isDownloadLinkShown: true,
          downloadLink: response.data.path
        })
      }
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  closeDownloadFileLink = () => {
    this.setState({
      isDownloadButtonShown: true,
      isDownloadLinkShown: false
    })
  }

  render() {
    return (
      <div className="row">
        { process.env.REACT_APP_ENVIRONMENT === `DEVELOPMENT` ? <JsonVisualizer json={{ data: this.state.data }} /> : ``}

        { this.state.isDownloadLinkShown ?
          <div className="alert alert-success alert-rounded">
            <i className="ti-cloud-down"></i> <a target="blank" href={ this.state.downloadLink } style={{ color: '#155724' }}>O arquivo solicitado está pronto. Clique aqui para fazer o download!</a>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true" style={{ padding: '0 10px' }} onClick={this.closeDownloadFileLink}>×</span>
            </button>
          </div>
        :
          ''
        }

        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row gx-3 gy-2 align-items-right">
                <div className="col-sm-7">
                  <h4 className="card-title">Tabela de Custo Fornecedor</h4>
                  <h6 className="card-subtitle">{this.state.longDateString}</h6>
                </div>

                <div className="col-sm d-flex justify-content-end">
                  { this.state.isDownloadButtonShown ?
                    <a href="/#" className="link" title="Download Supplier Cost List in Excel File" onClick={this.getDownloadFileLink}>
                      <i className="ti-cloud-down supplier-cost-download-icon"></i>
                    </a>
                  :
                    ''
                  }

                  <DatePicker 
                    dateFormat="MM/yyyy"
                    selected={this.state.date}
                    onChange={(date) => this.setDate(date)}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="Referência"
                    maxDate={new Date()}
                  />

                  <select className="custom-select pull-right" onChange={this.walletSelector}>
                    <option value="">Carteiras</option>
                    <option value="">Todas</option>
                    {localStorage.wallets.split(',').map((wallet) => <option value={wallet}>{wallet}</option>)}
                  </select>

                  <select className="custom-select pull-right" onChange={this.costCenterSelector}>
                    <option value=" ">Centro de Custo</option>
                    {this.state.costCenterList.map((cost) => <option value={cost.value}>{cost.description}</option>)}
                  </select>
                </div>
              </div>

              <hr />
                
              <div className="table-responsive">
                {this.state.isLoading
                  ?
                  <LoadingAnimation />
                  :
                  <table className="table stylish-table">
                    <thead>
                      <tr>
                        <th>Tipo Débito</th>
                        <th>Descrição</th>
                        <th className="text-right">Quantidade</th>
                        <th className="text-right">Valor</th>
                        <th className="text-right">Total</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <h6><span className="link" style={{ color: 'limegreen', fontWeight: 'bold' }}>TOTAL GERAL</span></h6>
                        </td>
                        <td>
                          <h5 className="text-right">
                            <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.totalQuantity}</span>
                          </h5>
                        </td>
                        <td>
                          <h5 className="text-right">
                            <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.totalAmount}</span>
                          </h5>
                        </td>
                        <td>
                          <h5 className="text-right">
                            <span style={{ color: 'limegreen', fontWeight: 'bold' }}>{this.state.data.total}</span>
                          </h5>
                        </td>
                      </tr>
                      {this.state.data.detail.map((value, key) => 
                        <tr key={key}>
                          <td style={{ width: '120px' }}>
                            <h6>{value.product_id}</h6>
                          </td>
                          <td>
                            <h6>{value.description}</h6>
                          </td>
                          <td>
                            <h6 className="text-right">{value.qty}</h6>
                          </td>
                          <td>
                            <h6 className="text-right">{Helpers.currencyMask(value.price)}</h6>
                          </td>
                          <td>
                            <h6 className="text-right">{Helpers.currencyMask(value.total)}</h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SupplierCost
import React, { Component } from 'react'

class JsonVisualizer extends Component {
  render() {
    return(
      <div className="col-lg-12 col-md-12">
        <div className="card card-inverse card-info">
          <div className="card-body">
            <div className="d-flex">
              <div className="m-r-20 align-self-center">
                <h1 className="text-white"><i className="ti-server"></i></h1>
              </div>
              <div>
                <h3 className="card-title">API RESPONSE</h3>
                <h6 className="card-subtitle">This card appears only in development server.</h6>
              </div>
            </div>

            <div className="row">
              <div className="col-12 align-self-center">
                <h2 className="font-light text-white" style={{ fontSize: '16px' }}>
                  <b>JSON:</b> <pre>{JSON.stringify(this.props.json.data)}</pre>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default JsonVisualizer
import Helpers from '../../presentation/helpers/index'

class InvoicesUsecase {
  tableFields = {
    detail: [], 
    total: 0, 
    totalAmount: 0, 
    totalQuantity: 0,
    totalLatePayment: 0
  }

  setResponseToTableFields = (response) => {
    return {
      detail: response.data.detail,
      total: Helpers.currencyMask(response.data.billing),
      paid: Helpers.currencyMask(response.data.paid),
      totalAmount: Helpers.currencyMask(response.data.amount_consumed),
      totalQuantity: response.data.qty,
      totalLatePayment: response.data.total_late_payment
    }
  }
}

export default InvoicesUsecase
import React, { Component } from 'react'
import AxiosHttpClient from '../../../infrastructure/http/axios-http-client'
import AuthTokenHelper from '../../helpers/auth-token-helper'
import backgroundImage from '../../assets/images/background.jpg'
import logoImage from '../../assets/images/blue-logo.png'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoginShown: true,
      isRecoveryShown: false,
      isSendEmailMessageShown: false,
      loginCheckbox: false
    }

    this.authTokenHelper = new AuthTokenHelper()
  }
  
  toRecover = (event) => {
    this.setState({
      isLoginShown: false,
      isRecoveryShown: true
    })
  }

  handleChangeLoginCheckbox = (event) => {
    this.setState({
      loginCheckbox: !this.state.loginCheckbox
    })
  }

  login = (event) => {
    event.preventDefault()
    let email = event.target.email.value
    let password = event.target.password.value

    const callingLoginEndpoint = async (e) => {
      let alertParams = ''

      await AxiosHttpClient.post('/login', `{ "email": "${email}", "password": "${password}" }`, { headers: { 'Content-Type': 'application/json' }})
  
      .then((response) => {
        if(response.status === 200) {
          alertParams = {
            code: response.status,
            title: 'Sucesso!',
            text: 'Login realizado com sucesso.',
            icon: 'success'
          }

          localStorage.setItem('x-access-token', response.data.accessToken)
          localStorage.setItem('nickname', response.data.name)
          localStorage.setItem('cost-center', this.authTokenHelper.getCostCenter(response.data.accessToken))
          localStorage.setItem('partners', response.data.wallets)
        }

        else {
          alertParams = {
            code: response.response.status,
            title: 'Ooops...',
            text: 'Login não autorizado.',
            icon: 'warning'
          }
        }
      })
      
      .catch((error) => {
        alertParams = {
          code: 500, // To get the error response status, use error.response.status
          title: 'Erro!',
          text: 'Erro interno de servidor. Por favor, contate o administrador.',
          icon: 'error'
        }
      })

      this.props.handleLogin(alertParams)
    }

    callingLoginEndpoint()
  }

  recover = (event) => {
    event.preventDefault()

    let email = event.target.email.value

    AxiosHttpClient.post('/login/request-password', `{ "email": "${email}" }`, { headers: { 'Content-Type': 'application/json' }})

    .then((response) => {
      if(response.data && response.data !== '') {
        if(response.data.email) {
          this.setState({ isSendEmailMessageShown: true })
        }
      }
    })
    
    .catch((error) => {
      console.log(`API integration error.`, error)
    })
  }

  render() {
    return(
      <section id="wrapper" className="login-register login-sidebar" style={{backgroundImage: `url(${backgroundImage})`, boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.45)'}}>
        <div className="login-box card">
          <div className="card-body">
            <form className="form-horizontal form-material" id="loginform" action="#" onSubmit={this.login} style={{display: this.state.isLoginShown ? 'block' : 'none'}}>
              <a href="/#" className="text-center db"><img src={logoImage} alt="Home page" width="70" /></a>
              
              <div className="form-group m-t-40">
                <div className="col-xs-12">
                  <input className="form-control email-field" type="email" required={true} placeholder="E-mail" name="email" autoComplete="current-username"></input>
                </div>
              </div>
              
              <div className="form-group">
                <div className="col-xs-12">
                  <input className="form-control password-field" type="password" required={true} placeholder="Senha" name="password" autoComplete="current-password"></input>
                </div>
              </div>
  
              <div className="form-group">
                <div className="col-md-12">
                  <div className="checkbox checkbox-primary pull-left p-t-0" style={{ display: 'none' }}>
                    <input id="checkbox-signup" type="checkbox" defaultChecked={this.state.loginCheckbox} onChange={this.handleChangeLoginCheckbox}></input>
                    <label htmlFor="checkbox-signup">Lembrar-me</label>
                  </div>
                  
                  <a href="/#" id="to-recover" onClick={this.toRecover} className="text-dark pull-right"><i className="fa fa-lock m-r-5"></i>Recuperar senha</a>
                </div>
              </div>
  
              <div className="form-group text-center m-t-20">
                <div className="col-xs-12">
                  <button className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Entrar</button>
                </div>
              </div>
            </form>
  
            <form className="form-horizontal" id="recoverform" action="#" onSubmit={this.recover} style={{display: this.state.isRecoveryShown ? 'block' : 'none'}}>
              <div className="form-group" style={{display: this.state.isSendEmailMessageShown ? 'none' : 'block'}}>
                <div className="col-xs-12">
                  <h3>Recuperação de senha</h3>
                  <p className="text-muted">Digite o e-mail cadastrado para iniciar a recuperação de sua senha.</p>
                </div>
              </div>
  
              <div className="form-group" style={{display: this.state.isSendEmailMessageShown ? 'none' : 'block'}}>
                <div className="col-xs-12">
                  <input className="form-control" type="text" required={true} placeholder="E-mail" name="email"></input>
                </div>
              </div>
  
              <div className="form-group text-center m-t-20" style={{display: this.state.isSendEmailMessageShown ? 'none' : 'block'}}>
                <div className="col-xs-12">
                  <button className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Recuperar</button>
                </div>
              </div>

              <div className="form-group" style={{display: this.state.isSendEmailMessageShown ? 'block' : 'none'}}>
                <div className="col-xs-12">
                  <h3>E-mail enviado!</h3>
                  <p className="text-muted">E-mail de recuperação de senha enviado.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default Login